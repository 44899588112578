<template>
    <div>
        <div class="container" id="trip-result-container">
            <div class="row mb-2">
                <div class="col-md-8 col-12">
                    <div id="bl-route-title">
                        <span class="text-blue-light" v-show="getCompanyInfo.id==1" id="bl-company-title"><i class="fa fa-bus pr-2 text-aqua"></i>{{tripResult.company_name}}</span>
                        <i class="fa fa-route pr-2 text-aqua"></i>{{tripResult.route_name}}
                        <br><small class="text-muted font-size-sm">{{tripResult.route_tagline}}</small>
                    </div>
                </div>
                <div class="col-md-4 col-12 text-right">
                    <span class="badge badge-pill  badge bg-dark-gray bigger-badge" style="margin-right: 1%;">{{tripResult.vehicle_type}}</span>
                    <span class="badge badge-pill  badge-danger bigger-badge" style="margin-left: 1%;">{{tripResult.coach_number}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12 text-center">
                    <div class="row">
                        <div class="col-md-6 col-6">
                            <p class="border-box-label">Start Point</p>
                            <div class="border-box text-capitalize" style="font-weight: 400;">{{tripResult.filter_from_station_name}}</div>
                        </div>
                        <div class="col-md-6 col-6">
                            <p class="border-box-label">End Point</p>
                            <div class="border-box text-capitalize" style="font-weight: 400;">{{tripResult.filter_to_station_name}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12 text-center">
                    <div class="row">
                        <div class="col-md-6 col-6">
                            <p class="border-box-label">Departure Time</p>
                            <div class="border-box" style="font-weight: 400;">{{tripResult.filter_from_station_start_time}}</div>
                        </div>
                        <div class="col-md-6 col-6">
                            <p class="border-box-label">Arrival Time</p>
                            <div class="border-box" style="font-weight: 400;">{{tripResult.filter_to_station_end_time}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12 text-center">
                    <div class="row">
                        <div class="col-md-4 col-3">
                            <p class="border-box-label">Seats Available</p>
                            <div class="border-box" style="font-weight: 400;">{{tripResult.available}}</div>
                        </div>
                        <div class="col-md-4 col-3">
                            <p class="border-box-label">Fare</p>
                            <div class="border-box" style="font-weight: 400;">{{tripResult.fare.join(',')}}</div>
                        </div>
                        <div class="col-md-4 col-6 pt-5">
                            <button class="btn btn-sm btn-block btn-primary" @click="toggleShowSeats(tripResult.id)">View Seats</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ShowSeats v-if="showTrip==tripResult.id"
                   :trip-id="tripResult.id"
                   :trip-route-id="tripResult.trip_route_id"
                   :from-station-id="tripResult.filter_from_station_id"
                   :to-station-id="tripResult.filter_to_station_id"
        ></ShowSeats>
    </div>

</template>

<script>
import ShowSeats from '@/view/content/ShowSeats';
import {mapGetters} from 'vuex';
export default {
    name: "TripSearchResult",
    components:{
      ShowSeats
    },
    props: ['tripResult','showTrip'],
    data : () =>{
        return{
            showSeats: 0
        }
    },
    computed:{
        ...mapGetters(['getCompanyInfo'])
    },
    methods:{
        toggleShowSeats(id){
            if(this.showSeats==0) this.showSeats = id;
            if(this.$props.showTrip!=id) this.showSeats = id;
            else this.showSeats = 0;
            this.$emit('seatToTrip', this.showSeats);
            //console.log(this.showSeats+" "+this.$props.showTrip);
        }
    },
    mounted(){
        //this.showSeats = this.$props.showTrip;
    }

}
</script>

<style scoped>
    #trip-result-container{
        /*width: 1170px;*/
        margin-top:10px;
        padding: 10px !important;
        background-color: whitesmoke
    }
    #bl-company-title{
        font-size: 18px;
        margin: 0px;
        padding: 0px;
        color: #20b887;
        padding-right: 15px;
    }
    #bl-route-title{
        font-size: 16px;
        margin: 0px;
        padding: 0px;
    }
    .bigger-badge{
        min-width: 49%;
    }
    .border-box-label {
        font-size: 12px;
        text-transform: uppercase;
        margin: 0px;
        padding: 0px;
        color: #aaaaaa;
    }
    .border-box{
        border: 1px solid #d6d6d6;
        font-size: 15px;
        color: #666666;
        margin: 0px;
        padding: 2px 5px;
        white-space: nowrap;
    }
</style>
