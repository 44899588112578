<template>
    <div>
        <div id="notice-panel" class="container rounded px-8 py-2" v-bind:class="{'notice-panel-good': getCompanyInfo.website_content.notice_good, 'notice-panel-bad': getCompanyInfo.website_content.notice_bad }" >
          <span v-if="getCompanyInfo.website_content.notice_good">{{getCompanyInfo.website_content.notice_good}}</span>
          <span v-else-if="getCompanyInfo.website_content.notice_bad">{{getCompanyInfo.website_content.notice_bad}}</span>
        </div>
        <div id="search-container" class="container rounded p-8">
            <div class="col-xs-12">
                <h1 v-if="getCompanyInfo.website_content.slogan_title1" class="slogan-title1 text-blue-dark">
                    {{getCompanyInfo.website_content.slogan_title1}}
                </h1>
                <h1 v-else class="slogan-title1 text-blue-dark">BOOK BUS TICKET INSTANTLY</h1>
            </div>
            <div class="row py-5">
                <div class="col-sm-3 col-12 mb-3">
                    <v-autocomplete
                            v-model="fromStation"
                            class = "form-control"
                            style="background-color: transparent; border: none;"
                            :items="stations"
                            item-text="name"
                            item-value="id"
                            solo
                            dense
                            label="From">
                    </v-autocomplete>
                </div>
                <div class="col-sm-3 col-12 mb-3">
                    <v-autocomplete
                            v-model="toStation"
                            class = "form-control"
                            style="background-color: transparent; border: none;"
                            :items="stations"
                            item-text="name"
                            item-value="id"
                            solo
                            dense
                            label="To">
                    </v-autocomplete>
                </div>
                <div class="col-sm-3 col-12 mb-3">
                    <DatePicker v-model="selectedDate" :min-date="minimumDate" :max-date="maximumDate" :masks="masks"  color="green" style="background-color: white;" >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 form-control"
                                    :value="inputValue"
                                    v-on="inputEvents"
                            />
                        </template>
                    </DatePicker>
                </div>
                <div class="col-sm-3 col-12">
                    <button id="trip_search_btn" class="btn rounded-pill btn-block bg-navy text-white"
                            :disabled="searching"
                            @click="searchTrips">
                        <i v-if="searching" class="fas fa-spinner fa-spin text-white pr-0"></i>
                        {{searching ? 'Searching...' : 'Search' }}
                    </button>
                </div>
            </div>
            <div class="col-xs-12">
                <h2 v-if="getCompanyInfo.website_content.slogan_title2" class="slogan-title2 text-aqua">
                    {{getCompanyInfo.website_content.slogan_title2}}
                </h2>
                <h2 v-else class="slogan-title2 text-aqua">BY CHOKROJAN</h2>
            </div>
            <div class="col-xs-12 mx-auto text-center pt-5">
              <img class="membership-img" width="auto" alt="Membership basis ecab" src="https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/images/membership-basis-ecab.png">
            </div>
            <div class="col-xs-12 mx-auto text-center pt-2">
              <img class="payment-img" height="auto" alt="Payment gateway visa master bkash nagad nexus" src="https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/images/visa-master-card-bkash-nexus-logo.webp">
            </div>
        </div>
        <template v-if="showTripResult && !searching">
          <div class="py-5">
            <TripSearchResult
                    v-for="trip in trips"
                    :key="trip.id"
                    :trip-result="trip"
                    :show-trip="showingTrip"
                    v-on:seatToTrip="onViewSeats">
            </TripSearchResult>
          </div>
        </template >
        <template v-if="showEmptyTripResult">
            <div class="container" id="trip-empty-container">
                <h2 class="text-center text-danger">Sorry, trips not available according to your search.</h2>
            </div>
        </template >
    </div>
</template>

<script>

import DatePicker from 'v-calendar/lib/components/date-picker.umd.min'
import tripService from '@/core/services/models/trip.service'
import moment from 'moment/src/moment'
import TripSearchResult from '@/view/content/TripSearchResult'
import {mapGetters} from 'vuex'

export default {
    name: "TripSearch",
    components:{
        DatePicker,
        TripSearchResult
    },
    data(){
        return{
            minimumDate: new Date(),
            fromStation : null,
            toStation : null,
            selectedDate : new Date(),
            trips : null,
            masks: {
                input: 'D MMM YYYY',
            },
            searching: false,
            afterSearch: false,
            showingTrip: 0,
        }
    },
    props :["stations"],
    methods:{
        searchTrips(){
            if(this.fromStation == null || this.toStation == null || this.selectedDate == null)
              return this.$toasted.error("Please select Stations and Date");
            dataLayer.push({
              event: 'tripSearch',
              pageUrl: window.location.href,
              pageTitle: this.getCompanyInfo.name + " - Trip Search :: " + this.fromStation + " -> " + this.toStation
            });

            this.searching = true;
            this.showingTrip = 0;
            let params = {
              from_station_id : this.fromStation,
              to_station_id   : this.toStation,
              journey_date    : moment(this.selectedDate).format('yyyy-MM-DD')
            };
            tripService.searchTrips(params , r=>{
              // this.trips = r;
              r = r.map( t => {
                  t.filter_from_station_start_time = moment(t.filter_from_station_start_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A');
                  t.filter_to_station_end_time = moment(t.filter_to_station_end_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A');
                  return t;
              });
              this.trips = r;
              this.searching = false;
              this.afterSearch = true;
            }, e => {
              console.log(e);
            });
        },
        onViewSeats (value) {
            this.showingTrip = value
        }
    },
    computed:{
        ...mapGetters(['getCompanyInfo']),
        showTripResult: function(){
            return this.trips != null && this.trips.length > 0;
        },
        showEmptyTripResult: function(){
            return this.trips != null && this.trips.length == 0 && this.afterSearch;
        },
        maximumDate: function () {
            if(this.getCompanyInfo) return  moment().add(this.getCompanyInfo.general_settings.advance_booking,'days').toDate();
            return moment().add(10,'days').toDate();
        }
    },
    mounted(){
        // this.maximumDate = moment().add(this.getCompanyInfo.general_settings.advance_booking,'days').toDate();
    }
}
</script>

<style scoped>
    #trip-empty-container{
        /*width: 1170px;*/
        margin-top:20px;
        padding: 30px !important;
        background-color: whitesmoke
    }
    #search-container {
        margin-top: 20px;
        background-color: rgba(0, 0, 0, .75);
    }
    #notice-panel {
        margin-top: 20px;
        height: 60px;
        font-size: 35px;
        text-align: center;
        vertical-align: middle;
    }
    .notice-panel-bad{
        background-color: rgba(237, 28, 36, .50);
        color: #ffffff;
    }
    .notice-panel-good{
      background-color: rgba(31, 184, 135, .50);
      color: #ffffff;
    }
    .form-control {
      font-size: 1.15rem !important;
      height: calc(1.5em + 1rem + 2px) !important;
    }
    .slogan-title1{
      font-size: 35px;
      text-align: left;
    }
    .slogan-title2{
      font-size: 30px;
      text-align: right;
    }
    .payment-img{
      width: 80%;
    }
    .membership-img{
      height: 45px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1399px) {
        #notice-panel {
            height: 60px;
        }
    }
    @media screen and (max-width: 1023px) {
        #notice-panel {
            height: 40px;
            font-size: 20px;
        }
    }
    @media screen and (max-width: 900px) {
      .slogan-title1{
        font-size: 25px;
        text-align: center;
      }
      .slogan-title2{
        font-size: 20px;
        text-align: center;
      }
      .payment-img{
        width: 100%;
      }
      .membership-img{
        height: 30px;
      }
    }
</style>
