import axios from 'axios';
import tripUrls from '@/core/services/constants/TripUrls'

const tripService = {
    searchTrips(params, success, error) {
        axios.get(tripUrls.searchTrips, {
            params: params
        }).then(responseData => {
            success(responseData.data.data)
        }).catch(errorResponse => error(errorResponse.response.data.errors))
    },

    getBoardingAndDroppingCounters(params, success, error) {
        axios.get(tripUrls.getBoardingAndDroppingCounters, {
            params: params
        }).then(responseData => {
            success(responseData.data.data)
        }).catch(errorResponse => error(errorResponse.response.data.errors))
    },

    getSeatLayout(params, success, error) {
        axios.get(tripUrls.seatLayoutSearch, {
            params: params
        }).then(responseData => {
            success(responseData.data.data)
        }).catch(errorResponse => error(errorResponse.response.data.errors))
    },
    selectSeat(params, success, error) {
        axios.get(tripUrls.selectTheSeat, {
            params: params
        }).then(responseData => {
            success(responseData.data)
        }).catch(errorResponse => error(errorResponse.response.data.errors))
    },
    deselectSeat(params, success, error) {
        axios.get(tripUrls.deselectTheSeat, {
            params: params
        }).then(responseData => {
            success(responseData.data)
        }).catch(errorResponse => error(errorResponse.response.data.errors))
    },

    bookATicket(params,success, error){
        axios.post(tripUrls.bookTicket,params)
            .then( responseData =>{
                success(responseData.data.ticket_id)
            }).catch( errorResponse => error(errorResponse.response.data.errors))
    }

};

export default tripService;





