<template>
    <div>
        <div class="form-group col-md-12 px-0">
            <h3 class="text-blue" style="margin: 0px;"><span style="color:#ffffff;">Already have an account?</span>
                <br>
                <router-link :to="'/login'">Then sign-in first to avoid<br>mobile number verification.</router-link>
            </h3>
        </div>
        <v-form
                ref="form"
                v-model="valid"
        >
            <span class="input-level text-white"><i class="far fa-fw fa-star text-danger"></i> Name:</span>
            <v-text-field
                    v-model="name"
                    dark
                    outlined
                    dense
                    :rules="[ v => !!v || 'Name is required', v => (v && v.length >= 2)  || 'Name must be greater than 2 characters',]"
                    required
            ></v-text-field>
            <span class="input-level text-white"><i class="far fa-fw fa-star text-danger"></i> Mobile Number:</span>
            <v-text-field
                    v-model="mobile_number"
                    dark
                    outlined
                    dense
                    :rules="[ v => !!v || 'Mobile Number is required', v => (v && v.length === 11)  || 'Phone number must be 11 digits',]"
                    required
            ></v-text-field>
            <div id="mobile-verify" v-if="otpSent">
                <span class="input-level text-white"><i class="far fa-fw fa-star text-danger"></i> OTP Code:</span>
                <v-text-field
                        v-model="otp"
                        dense
                        dark
                        outlined
                        :rules="[ v => !!v || 'OTP is required', v => (v && v.length == 6)  || 'OTP must be 6 digits',]"
                        required
                ></v-text-field>
            </div>
            <div class="row">
                <div class="col-md-4 offset-md-4">
                    <button class="btn btn-primary rounded-pill btn-block " v-if="!otpSent" :disabled="!valid"  @click="sendOtp">
                        Send OTP
                    </button>
                    <button class="btn btn-primary rounded-pill btn-block" v-else :disabled="!valid" @click="loginWithOtp">
                        Verify OTP
                    </button>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
    import authService from "@/core/services/auth/auth.service";
    import {ACTION_SET_TOKEN} from "@/core/services/store/authentication.module";

    export default {
        name: "TripLogin",
        data(){
            return {
                valid : false,
                name: null,
                mobile_number: null,
                otp: null,
                otpSent : false
            }
        },
        methods : {
            loginWithOtp(e){
                // console.log(this.$route); return;
                e.preventDefault();
                authService.loginWithOtp({
                    name:this.name,
                    mobile_number:this.mobile_number,
                    otp:this.otp
                }, (responseData) => {
                    //console.log(responseData);
                    this.$store.dispatch(ACTION_SET_TOKEN,responseData).then(()=>{
                            this.$toasted.success("Login successful")
                        }
                    );
                },(error)=>{
                    this.$toasted.error(error)
                })
            },
            sendOtp(e){
                e.preventDefault();
                this.otpSent = true;
                authService.requestForOtp({mobile_number:this.mobile_number},()=>{
                    this.otpSent = true;
                    this.$toasted.success("OTP sent successfully");
                },(error)=>{
                    this.$toasted.error(error)
                })
            },
        }
    }
</script>

<style scoped>

</style>
