<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="container" id="show-seats-container">
        <transition name="fade">
            <div class="row" v-if="seatPlan != null && seatLayout != null">
                <div class="col-md-4 col-12">
                    <div class="row">
                        <div class="col-3 pr-0">
                            <div class="row">
                                <div class="col-md-12 m-1">
                                    <button class="btn btn-sm rounded-pill btn-block bg-white legend-button">Available</button>
                                </div>
                                <div class="col-md-12 m-1">
                                    <button class="btn btn-sm rounded-pill btn-block bg-lime legend-button">Selected</button>
                                </div>
                                <div class="col-md-12 m-1">
                                    <button class="btn btn-sm rounded-pill btn-block bg-light-blue legend-button">Engaged</button>
                                </div>
                                <div class="col-md-12 m-1">
                                    <button class="btn btn-sm rounded-pill btn-block bg-red legend-button">Occupied</button>
                                </div>
                            </div>
                        </div>
                        <div id="seat-plan-div" class="form-group col-9">
                            <div style="border:1px dotted #CCCCCC; padding-top:5px; padding-bottom:5px; min-height:355px; border-radius: 5px;">
                                <div class="row m-0 p-1" v-if="seatLayout.deck_type_id==2">
                                    <div class="col-12 text-center bg-info text-white">Lower Deck</div>
                                </div>
                                <div class="row m-0 p-0" v-for="(rows,r) in seatLayout.number_of_rows" :key="`1-${rows}`">
                                    <div v-for="(col,c) in seatLayout.number_of_columns" :key="`1-${col}`"
                                         :class="['bsp',classOfEachSeat]"
                                    >
                                        <v-tooltip top v-if="seatPlan[1][r+','+c]">
                                            <template v-slot:activator="{ on, attrs }">
                                                <b-button
                                                          pill
                                                          v-bind="attrs"
                                                          v-on="on"
                                                          class = "btn-block seat-info"
                                                          :class="{
                                                          'bg-lime': seatPlan[1][r+','+c].selected ,
                                                          'bg-white': seatPlan[1][r+','+c].status == 'available' && !seatPlan[1][r+','+c].selected,
                                                          'bg-red': seatPlan[1][r+','+c].status == 'occupied',
                                                          'bg-light-blue': seatPlan[1][r+','+c].status == 'engaged'}"
                                                          style="margin:3px 0px; padding:6px 3px;border-radius:12px;"
                                                          @click="determineSelect(1,r,c)"
                                                          v-if="seatPlan[1][r+','+c]">
                                                    {{seatPlan[1][r+','+c].seat_number}}
                                                </b-button>
                                            </template>
                                            <span>{{seatPlan[1][r+','+c].seat_type.name}}</span>
                                        </v-tooltip>

                                    </div>
                                </div>
                                <div class="row m-0 p-1" v-if="seatLayout.deck_type_id==2">
                                    <div class="col-12 text-center bg-info text-white">Upper Deck</div>
                                </div>
                                <div class="row m-0 p-0" v-for="(rows,r) in seatLayout.dd_number_of_rows" :key="`2-${rows}`">
                                    <div v-for="(col,c) in seatLayout.dd_number_of_columns" :key="`2-${col}`"
                                         :class="['bsp',classOfEachSeat]"
                                    >
                                        <v-tooltip top v-if="seatPlan[2][r+','+c]">
                                            <template v-slot:activator="{ on, attrs }">
                                                <b-button
                                                        pill
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class = "btn-block seat-info"
                                                        :class="{
                                                          'bg-lime': seatPlan[2][r+','+c].selected ,
                                                          'bg-white': seatPlan[2][r+','+c].status == 'available' && !seatPlan[2][r+','+c].selected,
                                                          'bg-red': seatPlan[2][r+','+c].status == 'occupied',
                                                          'bg-light-blue': seatPlan[2][r+','+c].status == 'engaged'}"
                                                        style="margin:3px 0px; padding:6px 3px;border-radius:12px;"
                                                        @click="determineSelect(2,r,c)"
                                                        v-if="seatPlan[2][r+','+c]">
                                                    {{seatPlan[2][r+','+c].seat_number}}
                                                </b-button>
                                            </template>
                                            <span>{{seatPlan[2][r+','+c].seat_type.name}}</span>
                                        </v-tooltip>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="row">
                        <div class="form-group col-md-12 text-danger">
                            <label for="boarding_counter_id" class="text-white">
                                <i class="far fa-fw fa-star text-danger"></i>Boarding Point</label>
                            <select id="boarding_counter_id" class="form-control" v-model="boarding_counter_id">
                                <option disabled :value="null">Select Boarding Point...</option>
                                <option v-for="counter in boardingCounters" :key="counter.counter_id"
                                        :value="counter.counter_id">{{counter.arrival_time}} -- {{counter.counter_name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-12 text-danger">
                            <label for="dropping_counter_id" class="text-white">
                                <i class="far fa-fw fa-star text-danger"></i>Dropping Point</label>
                            <select name="dropping_counter_id" v-model="dropping_counter_id"
                                    id="dropping_counter_id" class="form-control">
                                <option disabled :value="null">Select Dropping Point...</option>
                                <option v-for="counter in droppingCounters" :key="counter.counter_id"
                                        :value="counter.counter_id">{{counter.arrival_time}} -- {{counter.counter_name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-12">
                            <table id="sell-seat-table" class="table table-bordered" style="background: #ffffff;">
                                <tbody>
                                <tr>
                                    <th width="10%" style="text-align:center">#</th>
                                    <th width="20%" style="text-align:left">SEAT</th>
                                    <th width="40%" style="text-align:left">TYPE</th>
                                    <th width="30%" style="text-align:right">FARE</th>
                                </tr>
                                <tr v-for="(value,key,index) in selectedSeats" :key="key">
                                    <td>{{index+1}}</td>
                                    <td>{{value.seat_number}}</td>
                                    <td>{{value.seat_type.name}}</td>
                                    <td class="text-right">{{value.fare}}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td style="text-align:right" colspan="3">Sub Total :</td>
                                    <td style="text-align:right" id="sub-total">{{subTotal}}</td>
                                </tr>
                                <tr>
                                    <td style="text-align:right" colspan="3">Discount :</td>
                                    <td style="text-align:right" id="discount-amount">0</td>
                                </tr>
                                <tr>
                                    <td style="text-align:right" colspan="3">Service Charge :</td>
                                    <td style="text-align:right" id="service-charge">{{serviceCharge}}</td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td style="text-align:left" colspan="2">Total Seat : {{totalSeats}}</td>
                                    <td style="text-align:right">Grand Total :</td>
                                    <td style="text-align:right">{{grandTotal}}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <TripLogin v-if="!userInfo"></TripLogin>
                    <div v-else>
                        <div class="form-group col-md-12 px-0 text-blue-light">
                            <h4 style="margin: 0px;">Your ticket SMS will be sent to this number.</h4>
                        </div>
                        <div class="radio">
                            <label class="radio-inline text-aqua">
                                <input type="radio" name="optionsRadios" @change="setFormInputValuesAccordingly()" v-model="bookingFor" id="booking-for-self" value="self" checked="true" class="book-for-opt">
                                BOOKING FOR SELF
                            </label>
                            <label class="radio-inline text-white">
                                <input type="radio" name="optionsRadios" @change="setFormInputValuesAccordingly()" v-model="bookingFor" id="book-for-other" value="other" class="book-for-opt">
                                BOOKING FOR OTHERS
                            </label>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="passenger_mobile" class="text-white">
                                    <i class="far fa-fw fa-star text-danger"></i>Passenger Mobile Number</label>
                                <input type="text" class="form-control" :class="{'disabled-input':bookingFor == 'self'}" v-model="mobile_number" id="passenger_mobile" name="passenger_mobile" maxlength="11" value="" :disabled="bookingFor == 'self'">
                                <i class="validation_msg"></i>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-8">
                                <label for="passenger_name" class="text-white"><i class="far fa-fw fa-star text-danger"></i>Passenger Full Name</label>
                                <input type="text" class="form-control" :class="{'disabled-input':bookingFor == 'self'}" v-model="name" id="passenger_name" name="passenger_name" value="" :disabled="bookingFor == 'self'">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="passenger_gender" class="text-white">Gender</label>
                                <select id="passenger_gender" name="passenger_gender" v-model="gender" :class="{'disabled-input':bookingFor == 'self'}" class="form-control" :disabled="bookingFor == 'self'">
                                    <option v-for="g in genders" :key="g.value" v-bind:value="g.value">
                                        {{ g.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!--<div class="row pr-10">-->
                            <!--<div class="form-group col-md-8">-->
                                <!--<label for="promo_code"  class="text-white">Promo Code</label>-->
                                <!--<input type="text" class="form-control" v-model="promo_code" id="promo_code" name="promo_code" value="">-->
                                <!--<i class="validation_msg"></i>-->
                            <!--</div>-->
                            <!--<div class="col-md-4 pt-3">-->
                                <!--<label>&nbsp;</label>-->
                                <!--<button data-v-009eb4ec="" class="btn rounded-pill btn-block legend-button" style="background-color: #1F876B;color:white">Apply Promo</button>-->
                            <!--</div>-->
                        <!--</div>-->
                        <div class="row">
                            <div class="form-group col-md-12" style="color: #ffffff">
                                <input type="checkbox" required="" v-model="terms_agreed" name="terms_agreed" id="terms_agreed"> <label class="text-white" for="terms_agreed">I agree to all the </label> <router-link :to="'/terms-of-use'" target="_blank"><a style="text-decoration: underline;">TERMS AND CONDITIONS</a></router-link>
                            </div>
                            <div class="form-group col-md-12" align="center" v-show="ticket_timer_show">
                                <i class="far fa-clock text-danger fa-2x"> <span id="ticket-timer pl-2"> {{ticket_timer}}</span></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-8 offset-md-2" align="center">
                                <button @click="bookTicket" class="btn btn-primary rounded-pill m-1" style="width:40%">Buy Now</button>
                                <button @click="reset" class="btn rounded-pill" style="background-color: white;color: black;width: 40%">Reset</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </transition>

        <div class="text-center" v-if="seatLayout == null && seatPlan == null">
                Loading...
        </div>

    </div>

</template>

<script>
import Vue from 'vue'
import tripService from '@/core/services/models/trip.service'
import TripLogin from "@/view/content/TripLogin";
import {mapGetters} from 'vuex';
import moment from 'moment/src/moment';

export default {
    name: "ShowSeats",
    components:{
      TripLogin
    },
    props:['tripId','tripRouteId','fromStationId','toStationId'],
    data (){
      return{
          selectedBookingType: 'own',
          dropping_counter_id: null,
          boarding_counter_id: null,
          boardingCounters: [],
          droppingCounters: [],
          seatLayout : null,
          seatPlan : null,
          selectedSeats : {},
          serviceCharges:null,
          maxSeats : null,
          bookingFor : 'self',
          name:'',
          mobile_number :'',
          gender:'',
          genders:[{name:'Male', value:'male'},{name:'Female', value:'female'}],
          promo_code:'',
          terms_agreed:false,
          ticket_timer_show: false,
          ticket_timer: '',
          ticket_timeer_counter: null,
      }
    },
    methods:{
        bookTicket() {
            if (!this.terms_agreed)
                return this.$toasted.error('You must agree to the terms and conditions.');
            let selectedSeats = Object.keys(this.selectedSeats).map((key, idx) => this.selectedSeats[key].seat_number).sort();
            let selectedSeatsIds = Object.keys(this.selectedSeats).map((key, idx) => this.selectedSeats[key].id).sort();
            let selectedSeatsFares = Object.keys(this.selectedSeats).map((key, idx) => this.selectedSeats[key].fare).sort();
            if (selectedSeats.length == 0)
                return this.$toasted.error('Please select a seat');
            //console.log(this.boarding_counter_id);
            //console.log(this.tripId);
            if (this.boarding_counter_id == null || this.dropping_counter_id == null)
                return this.$toasted.error('Please select a boarding and dropping counter');
            let data = {
                //other fields that are required to create person
                "trip_id"             : this.tripId, //mandatory,
                "trip_route_id"       : this.tripRouteId,
                "boarding_counter_id" : this.boarding_counter_id, //mandatory
                "dropping_counter_id" : this.dropping_counter_id, //mandatory
                "seat_numbers"        : selectedSeats, //mandatory
                "seat_numbers_ids"    : selectedSeatsIds,
                "seat_numbers_fares"  : selectedSeatsFares
            };
            if (this.bookingFor === 'other') {
                if(this.mobile_number.length!=11){
                    return this.$toasted.error('Please input a correct mobile number');
                }
                if(this.name==''){
                    return this.$toasted.error('Passenger name is a required field');
                }
                if(this.gender==''){
                    return this.$toasted.error('Passenger gender is a required field');
                }
                data.mobile_number = this.mobile_number;
                data.name          = this.name;
                data.gender        = this.gender;
            }
            this.ticketTimerOff();
            tripService.bookATicket(data, ticketId => {
                this.$toasted.success("Ticket Booked");
                //console.log(ticketId)
                this.$router.push({path:"/profile/ticket-details/"+ticketId},()=>{
                });
            }, error => {
                this.$toasted.error('Could not book ticket')
            });
        },
        setFormInputValuesAccordingly(){
            if(this.bookingFor == 'self'){
                this.name          = this.userInfo.name;
                this.gender        = this.userInfo.gender;
                this.mobile_number = this.userInfo.mobile_number;
            }else {
                this.name          = '';
                this.mobile_number = '';
                this.gender        = '';
            }
        },
        /*getBoardingAndDroppingCounters(){
            tripService.getBoardingAndDroppingCounters({
                from_station_id : this.fromStationId,
                to_station_id   : this.toStationId,
                trip_id         : this.tripId,
                trip_route_id   : this.tripRouteId
            }, r => {
                this.boardingCounters = r.boarding_counters;
                this.droppingCounters = r.dropping_counters;
            }, e => console.log(e), e=> console.log(e))
        },*/
        getSeatLayout() {
            tripService.getSeatLayout({
                trip_id         : this.tripId,
                trip_route_id   : this.tripRouteId,
                from_station_id : this.fromStationId,
                to_station_id   : this.toStationId
            }, r => {
                this.seatLayout       = r.seat_layout;
                this.seatPlan         = r.seat_plan;
                this.maxSeats         = r.max_seats;
                this.serviceCharges   = r.service_charges;
                r.boarding_counters = r.boarding_counters.map( t => {
                  t.arrival_time = moment(t.arrival_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A');
                  return t;
                });
                r.dropping_counters = r.dropping_counters.map( t => {
                  t.arrival_time = moment(t.arrival_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A');
                  return t;
                });
                this.boardingCounters = r.boarding_counters;
                this.droppingCounters = r.dropping_counters;
                //console.log(r);
            }, e => console.log(e), e => console.log(e))
        },
        determineSelect(deck,row,column){
            let rowColStr = row+','+column;
            if(this.seatPlan[deck][rowColStr].status == "occupied" || this.seatPlan[deck][rowColStr].status == "engaged" || this.seatPlan[deck][rowColStr].seat_number=="#")
                return;
            if(!this.seatPlan[deck][rowColStr].selected && Object.keys(this.selectedSeats).length >= this.maxSeats ){
                return this.$toasted.error(`Max booking limit of  ${this.maxSeats} exceeded`)
            }
            if(this.seatPlan[deck][rowColStr].selected){
                tripService.deselectSeat({
                    ticket_id       : this.seatPlan[deck][rowColStr].id,
                    trip_id         : this.tripId,
                    trip_route_id   : this.tripRouteId,
                    seat_number     : this.seatPlan[deck][rowColStr].seat_number,
                }, r => {
                    if(r.data != undefined && r.data.is_selected == false){
                      Vue.set(this.seatPlan[deck][rowColStr],'selected',false);
                    }
                    this.updateSelectedSeats(deck,rowColStr);
                }, e => console.log(e), e => console.log(e))
            } else {
                tripService.selectSeat({
                    ticket_id       : this.seatPlan[deck][rowColStr].id,
                    trip_id         : this.tripId,
                    trip_route_id   : this.tripRouteId,
                    seat_number     : this.seatPlan[deck][rowColStr].seat_number,
                }, r => {
                    if(r.data != undefined && r.data.is_selected == true){
                      Vue.set(this.seatPlan[deck][rowColStr],'selected',true);
                    } else if(r.data != undefined && r.data.is_selected == false && r.data.is_engaged == true){
                      Vue.set(this.seatPlan[deck][rowColStr],'selected',false);
                      Vue.set(this.seatPlan[deck][rowColStr],'status','engaged');
                    }
                  this.updateSelectedSeats(deck,rowColStr);
                }, e => console.log(e), e => console.log(e))
            }
            //this.seatPlan[deck][rowColStr].selected = this.seatPlan[deck][rowColStr].selected? !this.seatPlan[deck][rowColStr].selected : true;
        },
        updateSelectedSeats(deck,rowColStr){
          if(rowColStr in this.selectedSeats) {
            Vue.delete(this.selectedSeats, rowColStr);
            let selectedSeats = Object.values(this.selectedSeats);
            if(selectedSeats.length<=0){
              this.ticketTimerOff();
            }
          } else if(this.seatPlan[deck][rowColStr].selected) {
            Vue.set(this.selectedSeats, rowColStr, this.seatPlan[deck][rowColStr]);
            let selectedSeats = Object.values(this.selectedSeats);
            if(selectedSeats.length>0){
              if(this.ticket_timer_show == false) this.ticketTimerOn();
            }
          }
          //console.log(JSON.stringify(this.selectedSeats));
        },
        ticketTimerOn(){
            this.ticket_timer_show = true;
            let firstSeatSelectTime = new Date();
            this.ticket_timeer_counter = setInterval(() => {
                let seatExpTime = new moment(firstSeatSelectTime, "YYYY-MM-DD HH:mm:ss");
                seatExpTime.add(this.getCompanyInfo.general_settings.selected_seat_lifetime,"seconds");
                let remainTime = moment.utc(moment(seatExpTime,"YYYY-MM-DD HH:mm:ss").diff(moment(new Date(),"YYYY-MM-DD HH:mm:ss"))).format("HH:mm:ss");
                if(new Date() < seatExpTime) {
                    this.ticket_timer = remainTime;
                }else {
                    this.reset();
                }
            }, 1000)
        },
        ticketTimerOff(){
            this.ticket_timer_show = false;
            this.ticket_timer = '';
            clearInterval(this.ticket_timeer_counter);
        },
        reset(){
            for (let key in this.selectedSeats) {
                let element = this.selectedSeats[key];
                let deck = element.deck_type_id;
                let rowColStr = element.row_column;
                tripService.deselectSeat({
                    ticket_id       : this.seatPlan[deck][rowColStr].id,
                    trip_id         : this.tripId,
                    trip_route_id   : this.tripRouteId,
                    seat_number     : this.seatPlan[deck][rowColStr].seat_number,
                }, r => {
                    if(r.data != undefined && r.data.is_selected == false){
                        Vue.set(this.seatPlan[deck][rowColStr],'selected',false);
                    }
                }, e => console.log(e), e => console.log(e));
                if(rowColStr in this.selectedSeats) {
                    Vue.delete(this.selectedSeats, rowColStr);
                }
            }
            this.ticketTimerOff();
        }
    },
    computed:{
      ...mapGetters(['isLoggedIn','userInfo','getCompanyInfo']),
      classOfEachSeat(){
          return "bsp"+this.seatLayout.number_of_columns;
      },
      totalSeats(){
        let selectedSeats = Object.values(this.selectedSeats);
        return selectedSeats.length;
      },
      subTotal(){
          let selectedSeats = Object.values(this.selectedSeats);
          if(selectedSeats.length == 0) return 0;
          return selectedSeats.map( s => Number(s.fare)).reduce((ac,fare) => ac+fare);
      },
      serviceCharge(){
          if(this.serviceCharges == null || this.selectedSeats == null)
              return 0;
          let serviceCharges = Object.keys(this.serviceCharges).reverse().map( s => Number(s));
          let totalFare = Object.values(this.selectedSeats).map(f=> Number(f.fare));
          if(totalFare.length == 0) return 0;
          totalFare = totalFare.reduce((ac,value) => ac+value);
          return Math.ceil(this.serviceCharges[serviceCharges.find(k => totalFare > k)] * totalFare / 100);
      },
      grandTotal(){
        if(this.serviceCharges == null || this.selectedSeats == null)
          return 0;
        let serviceCharges = Object.keys(this.serviceCharges).reverse().map( s => Number(s));
        let totalFare = Object.values(this.selectedSeats).map(f=> Number(f.fare));
        if(totalFare.length == 0) return 0;
        totalFare = totalFare.reduce((ac,value) => ac+value);
        return totalFare + Math.ceil(this.serviceCharges[serviceCharges.find(k => totalFare > k)] * totalFare / 100);
      }
    },
    mounted() {
        //this.getBoardingAndDroppingCounters();
        this.getSeatLayout();
    },
    beforeUpdate() {
        if(this.bookingFor == 'self'){
            this.setFormInputValuesAccordingly();
        }
    },
    beforeDestroy() {
        this.reset();
    }
}
</script>

<style scoped>
    .radio-inline, .checkbox-inline {
        display: inline-block;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        vertical-align: middle;
        cursor: pointer;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .bsp{
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .bsp4{
        width: 25%;
    }
    .bsp5{
        width: 20%;
    }
    .bsp6{
        width: 16%;
    }
    .bsp7{
        width: 14%;
    }
    .bsp8{
        width: 12%;
    }
    .hide{
        display: none;
    }
    .seat-info{
        margin: 3px 0px;
        padding: 6px 3px;
    }
    #seat-plan-div{
        border: 0px dotted #CCCCCC;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 5px !important;
        min-height: 355px;
    }
    .legend-button{
        font-size: 10px;
    }
    #show-seats-container{
        /*width: 1170px;*/
        background-color: rgba(0, 0, 0, .75);
        padding: 15px 10px 10px;
        /*border-top: 1px dashed #d6d6d6;*/
    }
    #sell-seat-table{
        line-height: 10px;
    }
    .radio, .checkbox {
        position: relative;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .disabled-input{
        background-color: darkgrey;
    }
    .btn-sm {
      padding: 0.55rem 0.25rem !important;
    }
</style>
